
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import MyWalletsSection from '@/components/sections/MyWalletsSection.vue'

export default defineComponent({
  name: 'MyWallets',
  components: {
    Main,
    MyWalletsSection
  }
})
